/** @format */

import { faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './FooterLink.module.scss';
import { FooterLinkInfo } from 'content';

export interface FooterLinkProps {
  footerLink: FooterLinkInfo;
}

const FooterLink: React.FC<FooterLinkProps> = ({ footerLink: { icon, title, href } }) => {
  return (
    <a className={styles.footerLink} href={href} target="_blank" rel="noopener noreferrer">
      <div className={styles.leftContent}>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
        <div>{title}</div>
      </div>
      <FontAwesomeIcon icon={faArrowUpRight} />
    </a>
  );
};

export default FooterLink;
