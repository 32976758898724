/** @format */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSidebarContent } from 'content';
import { useClearAllCookies } from 'contexts/CookieContext';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { getPathnameForPage } from 'routes';
import styles from './MobileNavLinks.module.scss';
import MobileFooterLink from './MobileFooterLink';
import { faPersonToDoor } from '@fortawesome/pro-regular-svg-icons';

export interface MobileNavLinksProps {
  closeNavLinks: () => void;
}

export const MobileNavLinks: React.FC<MobileNavLinksProps> = ({ closeNavLinks }) => {
  const sidebarContent = useSidebarContent();
  const clearAllCookies = useClearAllCookies();

  const onClickLogout = () => {
    clearAllCookies();
    closeNavLinks();
  };

  return (
    <div className={styles.mobileNavLinks}>
      <div className={styles.items}>
        {sidebarContent.sections.map((section) => (
          <div key={section.title}>
            <div className={styles.sectionTitle}>{section.title}</div>
            {section.pages.map((page) => (
              <NavLink
                key={page.title}
                to={getPathnameForPage(section, page)}
                title={page.title}
                className={styles.pageButton}
                activeClassName={styles.isActive}
                onClick={closeNavLinks}>
                <FontAwesomeIcon icon={page.icon} />
                {page.title}
              </NavLink>
            ))}
          </div>
        ))}
      </div>
      {sidebarContent.footerLinks.map((footerLink) => (
        <MobileFooterLink
          key={footerLink.title}
          title={footerLink.title}
          icon={footerLink.icon}
          href={footerLink.href}
          onClick={closeNavLinks}
        />
      ))}
      <MobileFooterLink title="Logout" icon={faPersonToDoor} onClick={onClickLogout} />
    </div>
  );
};
