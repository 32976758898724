/** @format */

import React from 'react';
import styles from './MobileNavBar.module.scss';
import { faBars, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyInfo } from 'components/CompanyInfo';

export interface MobileNavBarProps {
  isNavOpen: boolean;
  setIsNavOpen: (isOpen: boolean) => void;
}

export const MobileNavBar: React.FC<MobileNavBarProps> = ({ isNavOpen, setIsNavOpen }) => {
  return (
    <div className={styles.mobileNavBar}>
      <CompanyInfo className={styles.companyInfo} />
      <div className={styles.toggleIsNavOpenButton} onClick={() => setIsNavOpen(!isNavOpen)}>
        <FontAwesomeIcon icon={isNavOpen ? faXmark : faBars} className={styles.icon} />
      </div>
    </div>
  );
};
