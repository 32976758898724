/** @format */

import React from 'react';
import cx from 'classnames';

import piedPiperImage from 'images/pied_piper.png';
import styles from './CompanyInfo.module.scss';

export interface CompanyInfoProps {
  className?: string;
}

export const CompanyInfo: React.FC<CompanyInfoProps> = ({ className }) => {
  return (
    <div className={cx(styles.companyInfo, className)}>
      <img src={piedPiperImage} alt="logo" width={24} height={24} />
      <div className={styles.text}>Pied Piper</div>
    </div>
  );
};
