/** @format */
import cx from 'classnames';

import React, { useCallback, useState } from 'react';
import styles from './LoginOverlay.module.scss';
import exploLogo from 'images/icon.png';
import exploLogoSpinner from 'images/loading_spinner.gif';
import { isEmailAddress, isSpamEmail, isPersonalEmail } from 'utils/emailValidation';
import { getOrCreateCustomer, sendEmailToHubspot } from 'utils/customerUtils';
import { trackEvent } from 'analytics';
import Button from './Button';
import { Cookie, useCookie } from 'contexts/CookieContext';

// make sure logging in takes at least this long
// (to avoid flicker with logo -> spinning gif)
const MINIMUM_TIME_FOR_LOGGING_IN = 1_000;

const LoginOverlay: React.FC = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isCreatingCustomer, setIsCreatingCustomer] = useState(false);

  const [, setDemoEmail] = useCookie(Cookie.EXPLO_DEMO_EMAIL);
  const [, setUserGroupId] = useCookie(Cookie.USER_GROUP_ID);

  const onSubmit = useCallback(async () => {
    const trimmedEmail = email.trim();
    if (!isEmailAddress(trimmedEmail)) {
      setErrorMessage('You must use a valid email address');
    } else if (isSpamEmail(trimmedEmail)) {
      setErrorMessage('You must use a real email address.');
    } else if (isPersonalEmail(trimmedEmail)) {
      setErrorMessage('You must use your work email address');
    } else {
      setErrorMessage(undefined);
      setIsCreatingCustomer(true);
      setDemoEmail(trimmedEmail);

      const delay = new Promise((res) => setTimeout(res, MINIMUM_TIME_FOR_LOGGING_IN));

      // don't await/block
      sendEmailToHubspot(trimmedEmail).catch((e) => {
        console.error('Failed to send email to hubspot', e);
      });
      trackEvent('TEST_DEMO_APP_EMAIL', { email: trimmedEmail });

      try {
        const userGroupId = await getOrCreateCustomer();
        await delay;
        setUserGroupId(userGroupId);
      } catch (e) {
        console.error('Failed to create customer', e);
        setErrorMessage('Failed to create customer.');
      }
    }
  }, [email, setDemoEmail, setUserGroupId]);

  return (
    <div className={styles.loginOverlay}>
      <div className={styles.content}>
        <div className={styles.logoContainer}>
          <img
            alt="logo"
            src={isCreatingCustomer && errorMessage == null ? exploLogoSpinner : exploLogo}
            width={50}
          />
        </div>
        <h2 className={styles.title}>Get Access</h2>
        <p className={styles.blurb}>Enter your work email to get access to our demo application.</p>
        <div className={styles.form}>
          <input
            value={email}
            type="email"
            /* @ts-ignore */
            enterkeyhint="go"
            placeholder="Your (work) email address"
            disabled={isCreatingCustomer}
            autoFocus
            className={cx(styles.input, {
              [styles.error]: errorMessage != null,
            })}
            onChange={(event) => {
              setEmail(event.currentTarget.value);
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                onSubmit();
              }
            }}
          />
          <Button onClick={onSubmit} disabled={isCreatingCustomer}>
            Submit
          </Button>
        </div>
        <div
          className={cx(styles.errorMessage, {
            // always rendered so the page doesn't jump when it's rendered
            [styles.hidden]: errorMessage == null,
          })}>
          {errorMessage ??
            // dummy text so the div takes up space
            'XXX'}
        </div>
      </div>
    </div>
  );
};

export default LoginOverlay;
