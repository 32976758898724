/** @format */

const SPAM_DOMAINS = new Set([
  '167.com',
  '2.org',
  'admin.com',
  'amazongmail.com',
  'aosod.com',
  'breazeim.com',
  'consoft.com',
  'cunin01.com',
  'dayrep.us',
  'eurokool.com',
  'gottlieb.com',
  'hegrexa.com',
  'jourrapide.com',
  'mailinator.com',
  'linkein.com',
  'mirtox.com',
  'rhyta.com',
  'tasla.com',
  'teleworm.us',
  'wireps.com',
  'pacificwest.com',
  'temporary-mail.net',
  'bugfoo.com',
]);

const PERSONAL_DOMAINS = new Set([
  'gmail.com',
  'yahoo.com',
  'hotmail.com',
  'aol.com',
  'msn.com',
  'live.com',
  'outlook.com',
  'icloud.com',
  'mail.com',
  'comcast.net',
  'verizon.net',
  'ymail.com',
  'protonmail.com',
  'zoho.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'gmail.co.uk',
  'libero.it',
  'yahoo.fr',
  'web.de',
  'yandex.ru',
  'qq.com',
  '163.com',
  'naver.com',
  'hotmail.fr',
  'hotmail.de',
  'yahoo.co.jp',
  'yahoo.fr',
  'yahoo.co.uk',
  'yahoo.co.in',
  'yahoo.de',
  'hotmail.es',
  'hotmail.fr',
]);

export function isEmailAddress(email: string) {
  return email.includes('@');
}

export function isSpamEmail(email: string) {
  return SPAM_DOMAINS.has(getDomainFromEmail(email));
}

export function isPersonalEmail(email: string) {
  return PERSONAL_DOMAINS.has(getDomainFromEmail(email));
}

function getDomainFromEmail(email: string) {
  return email.split('@')[1];
}
