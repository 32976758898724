/** @format */

import { Page, Section } from 'content';

export const SIGNUP_ROUTE = '/signup';

export function getPathnameForSection(section: Section): string {
  return `/${normalize(section.title)}`;
}

export function getPathnameForPage(section: Section, page: Page): string {
  return `${getPathnameForSection(section)}/${normalize(page.title)}`;
}

function normalize(s: string): string {
  return s.toLowerCase().replace(' ', '-');
}
