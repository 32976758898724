/** @format */

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFile, faMessages, faUserDoctor } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';

/**
 * This controls the sidebar content for *desktop*.
 *
 * NOTE: Do not repeat section titles, or repeat page titles within a section.
 *       The URL of a page is determined by the section title + page title, so
 *       things will break if you have duplicates.
 */
const DESKTOP_SIDEBAR_CONTENT: SidebarContent = {
  sections: [
    {
      title: 'Analytics',
      pages: [
        {
          icon: faUserDoctor,
          title: 'Explo Editable Dashboard',
          iframeUrl: (userGroupId) =>
            `https://app.explo.co/iframe/PD1jaomxvm/${userGroupId}/production`,
        },
        {
          icon: faUserDoctor,
          title: 'Explo Report Builder',
          iframeUrl: (userGroupId) =>
            `https://app.explo.co/report-builder/iframe/z8L19X4x56/${userGroupId}/production`,
        },
      ],
    },
  ],
  footerLinks: [
    {
      icon: faMessages,
      title: 'Support',
      href: 'https://docs.explo.co/explo-help-desk',
    },
    {
      icon: faFile,
      title: 'Documentation',
      href: 'https://docs.explo.co/explo-help-desk',
    },
  ],
};

/**
 * This controls the sidebar content for *mobile*.
 *
 * NOTE: Do not repeat section titles, or repeat page titles within a section.
 *       The URL of a page is determined by the section title + page title, so
 *       things will break if you have duplicates.
 */
const MOBILE_SIDEBAR_CONTENT: SidebarContent = {
  sections: [
    {
      title: 'Analytics',
      pages: [
        {
          icon: faUserDoctor,
          title: 'Explo Dashboard',
          iframeUrl: (userGroupId) =>
            `https://app.explo.co/iframe/PD1jaomxvm/${userGroupId}/production`,
        },
      ],
    },
  ],
  footerLinks: [
    {
      icon: faMessages,
      title: 'Support',
      href: 'https://docs.explo.co/explo-help-desk',
    },
    {
      icon: faFile,
      title: 'Documentation',
      href: 'https://docs.explo.co/explo-help-desk',
    },
  ],
};

export interface SidebarContent {
  sections: Section[];
  footerLinks: FooterLinkInfo[];
}

export interface Section {
  title: string;
  pages: Page[];
}

export interface Page {
  title: string;
  icon: IconDefinition;
  iframeUrl: (userGroupId: string) => string;
}

export interface FooterLinkInfo {
  title: string;
  icon: IconDefinition;
  href: string;
}

export function useSidebarContent() {
  const screenWidth = useScreenWidth();
  return screenWidth >= 1000 ? DESKTOP_SIDEBAR_CONTENT : MOBILE_SIDEBAR_CONTENT;
}

function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenWidth;
}
