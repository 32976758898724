/** @format */

import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import App from 'components/App';
import { CookieContextProvider } from 'contexts/CookieContext';

const Root: React.FC = () => {
  return (
    <Router>
      <CookieContextProvider>
        <App />
      </CookieContextProvider>
    </Router>
  );
};

export default Root;
