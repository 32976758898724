/** @format */

export function identify(userId: number, payload: any) {
  // @ts-ignore
  window.analytics.identify(userId, payload);
}

export function resetUser() {
  // @ts-ignore
  window.analytics.reset();
}

export function pageView(pageName: string) {
  // @ts-ignore
  window.analytics.page(pageName);
}

export function trackEvent(eventName: string, eventProperties: any) {
  // @ts-ignore
  window.analytics.track(eventName, eventProperties);
}
