/** @format */

import React from 'react';
import cx from 'classnames';
import styles from './Button.module.scss';

const Button: React.FC<React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>> = (props) => {
  return <button {...props} className={cx(props.className, styles.button)} />;
};

export default Button;
