/** @format */

import React from 'react';

import styles from './MobileGetAccessLink.module.scss';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MobileGetAccessLink: React.FC = () => {
  return (
    <a
      className={styles.mobileGetAccessLink}
      href="https://www.explo.co/request-a-trial-demo-app"
      target="_blank"
      rel="noopener noreferrer">
      <div className={styles.text}>Get access to your own Explo Account</div>
      <FontAwesomeIcon icon={faArrowRight} />
    </a>
  );
};
