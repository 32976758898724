/** @format */

import { useSidebarContent } from 'content';
import React from 'react';
import styles from './Sidebar.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faMagnifyingGlass,
  faPersonToDoor,
} from '@fortawesome/pro-regular-svg-icons';
import { NavLink } from 'react-router-dom';
import { SIGNUP_ROUTE, getPathnameForPage } from 'routes';
import FooterLink from './FooterLink';
import cx from 'classnames';
import { useClearAllCookies } from 'contexts/CookieContext';
import { CompanyInfo } from 'components/CompanyInfo';

export interface SidebarProps {
  isLoggedIn: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ isLoggedIn }) => {
  const sidebarContent = useSidebarContent();
  const clearAllCookies = useClearAllCookies();

  return (
    <div className={styles.sidebar}>
      <div className={styles.companyInfoWrapper}>
        <CompanyInfo className={styles.companyInfo} />
      </div>
      <div className={styles.searchSection}>
        <div className={styles.search}>
          <div className={styles.iconContainer}>
            <FontAwesomeIcon icon={faMagnifyingGlass} size="xs" />
          </div>
          <input className={styles.input} placeholder="Search" />
        </div>
      </div>
      <div className={styles.items}>
        {sidebarContent.sections.map((section) => (
          <div key={section.title}>
            <div className={styles.sectionTitle}>{section.title}</div>
            {section.pages.map((page) => (
              <NavLink
                key={page.title}
                to={getPathnameForPage(section, page)}
                title={page.title}
                className={styles.pageButton}
                activeClassName={styles.isActive}>
                <FontAwesomeIcon icon={page.icon} />
                {page.title}
              </NavLink>
            ))}
          </div>
        ))}
        <NavLink to={SIGNUP_ROUTE} className={styles.signupLink} activeClassName={styles.isActive}>
          <div>Get access to your own Explo Account</div>
          <FontAwesomeIcon icon={faArrowRight} />
        </NavLink>
      </div>
      <div className={styles.footer}>
        <div className={styles.footerLinks}>
          {sidebarContent.footerLinks.map((footerLink) => (
            <FooterLink key={footerLink.title} footerLink={footerLink} />
          ))}
        </div>
        <div
          className={cx(styles.accountControls, {
            [styles.hidden]: !isLoggedIn,
          })}>
          <div className={styles.userInfo}>
            <div className={styles.userPicture} />
            <div className={styles.userName}>Donald Dunn</div>
          </div>
          <button onClick={clearAllCookies} className={styles.logout}>
            <FontAwesomeIcon icon={faPersonToDoor} size="lg" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
