/** @format */

import { IconDefinition, faArrowUpRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from './MobileFooterLink.module.scss';

export interface MobileFooterLinkProps {
  title: string;
  icon: IconDefinition;
  href?: string;
  onClick?: () => void;
}

const MobileFooterLink: React.FC<MobileFooterLinkProps> = ({ icon, title, href, onClick }) => {
  const content = (
    <>
      <div className={styles.leftContent}>
        <FontAwesomeIcon icon={icon} className={styles.icon} />
        <div>{title}</div>
      </div>
    </>
  );

  if (href) {
    return (
      <a
        className={styles.footerLink}
        href={href}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer">
        {content}
        <FontAwesomeIcon icon={faArrowUpRight} />
      </a>
    );
  } else {
    return (
      <div className={styles.footerLink} onClick={onClick}>
        {content}
      </div>
    );
  }
};

export default MobileFooterLink;
