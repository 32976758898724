/** @format */

import React from 'react';
import styles from './IFrame.module.scss';

export interface IFrameProps {
  src: string;
  title: string;
}

const IFrame: React.FC<IFrameProps> = ({ src, title }) => {
  return <iframe className={styles.iframe} title={title} src={src} />;
};

export default IFrame;
