/** @format */

import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Root from './Root.tsx';
import * as serviceWorker from './serviceWorker';

import './index.css';
import '@fontsource/inria-sans';

class MainApp extends Component {
  render() {
    return <Root />;
  }
}

ReactDOM.render(<MainApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
