/** @format */

import React, { useCallback, useContext } from 'react';
import Cookies from 'js-cookie';
import { createContext, useState } from 'react';

export interface CookieContextValue {
  cookies: AppCookies;
  setCookie: (key: Cookie, value: string) => void;
  removeCookie: (key: Cookie) => void;
  clearAllCookies: () => void;
}

export const Cookie = {
  EXPLO_DEMO_EMAIL: 'explo_demo_email',
  USER_GROUP_ID: 'user_group_id',
};
export type Cookie = typeof Cookie[keyof typeof Cookie];

export type AppCookies = Record<Cookie, string | undefined>;

const CookieContext = createContext<() => CookieContextValue>(() => {
  throw new Error('No CookieContextProvider found in tree');
});

export const CookieContextProvider: React.FC = ({ children }) => {
  const [cookies, setCookies] = useState<AppCookies>(() => ({
    [Cookie.EXPLO_DEMO_EMAIL]: Cookies.get(Cookie.EXPLO_DEMO_EMAIL),
    [Cookie.USER_GROUP_ID]: Cookies.get(Cookie.USER_GROUP_ID),
  }));

  const setCookie = useCallback((key: Cookie, value: string) => {
    setCookies((existing) => ({
      ...existing,
      [key]: value,
    }));
    Cookies.set(key, value);
  }, []);

  const removeCookie = useCallback((key: Cookie) => {
    setCookies(({ [key]: _, ...existing }) => existing);
    Cookies.remove(key);
  }, []);

  const clearAllCookies = useCallback(() => {
    setCookies({});
    for (const key of Object.values(Cookie)) {
      Cookies.remove(key);
    }
  }, []);

  const contextValue = useCallback(
    () => ({
      cookies,
      setCookie,
      removeCookie,
      clearAllCookies,
    }),
    [cookies, removeCookie, setCookie, clearAllCookies],
  );

  return <CookieContext.Provider value={contextValue}>{children}</CookieContext.Provider>;
};

export function useCookie(key: Cookie) {
  const { cookies, setCookie, removeCookie } = useContext(CookieContext)();

  return [
    cookies[key],
    useCallback((newValue: string) => setCookie(key, newValue), [key, setCookie]),
    useCallback(() => removeCookie(key), [key, removeCookie]),
  ] as const;
}

export function useClearAllCookies(): () => void {
  const { clearAllCookies } = useContext(CookieContext)();
  return clearAllCookies;
}
