/** @format */

import React, { useCallback, useEffect, useState } from 'react';

import Sidebar from 'components/navigation/Sidebar';
import styles from './App.module.scss';
import { useSidebarContent } from 'content';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import LoginOverlay from 'components/LoginOverlay';
import parse from 'url-parse';
import IFrame from 'components/IFrame';
import { SIGNUP_ROUTE, getPathnameForPage, getPathnameForSection } from 'routes';
import { Cookie, useCookie } from 'contexts/CookieContext';
import { MobileNavBar } from './navigation/mobile/MobileNavBar';
import { MobileGetAccessLink } from './navigation/mobile/MobileGetAccessLink';
import { MobileNavLinks } from './navigation/mobile/MobileNavLinks';

const App: React.FC = () => {
  const sidebarContent = useSidebarContent();

  const userGroupIdFromUrl = parse(window.location.href, true).query.user_group_id;
  const [userGroupId, setUserGroupId] = useCookie(Cookie.USER_GROUP_ID);

  const history = useHistory();
  const { pathname } = useLocation();
  useEffect(() => {
    if (userGroupIdFromUrl) {
      setUserGroupId(userGroupIdFromUrl);
      history.replace(pathname);
    }
  }, [history, pathname, setUserGroupId, userGroupIdFromUrl]);

  const isLoggedIn = !!userGroupIdFromUrl || !!userGroupId;

  const [isShowingMobileNav, setIsShowingMobileNav] = useState(false);
  const closeNavLinks = useCallback(() => {
    setIsShowingMobileNav(false);
  }, []);

  return (
    <div className={styles.app}>
      <div className={styles.sidebar}>
        <Sidebar isLoggedIn={isLoggedIn} />
      </div>
      <div className={styles.mobileNavBar}>
        <MobileNavBar isNavOpen={isShowingMobileNav} setIsNavOpen={setIsShowingMobileNav} />
      </div>
      <div className={styles.content}>
        <Switch>
          <Route path={SIGNUP_ROUTE}>
            <IFrame src="https://calendly.com/tryexplo/explo-intro-demo-app" title="signup" />
          </Route>
          {sidebarContent.sections.map((section) => (
            <Route key={section.title} path={getPathnameForSection(section)}>
              <Switch>
                {section.pages.map((page) => (
                  <Route key={page.title} path={getPathnameForPage(section, page)}>
                    {userGroupId && <IFrame title={page.title} src={page.iframeUrl(userGroupId)} />}
                  </Route>
                ))}
                <Route>
                  <Redirect to={getPathnameForPage(section, section.pages[0])} />;
                </Route>
              </Switch>
            </Route>
          ))}
          {/* default route (only redirect when logged in) */
          isLoggedIn && (
            <Route>
              <Redirect to={getPathnameForSection(sidebarContent.sections[0])} />;
            </Route>
          )}
        </Switch>
        {isShowingMobileNav ? (
          <div className={styles.mobileNav}>
            <MobileNavLinks closeNavLinks={closeNavLinks} />
          </div>
        ) : (
          <div className={styles.mobileGetAccessLink}>
            <MobileGetAccessLink />
          </div>
        )}
      </div>
      {!userGroupId ? <LoginOverlay /> : null}
    </div>
  );
};

export default App;
